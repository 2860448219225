import "./src/styles/app.css";

export const shouldUpdateScroll = ({
  routerProps: { location },
}) => {
  const { hash, pathname } = location;

  if ( pathname.startsWith('/topics/') && hash === "" ) {
    return false;
  }
};
